import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { DndProvider } from 'react-dnd-latest';
import { HTML5Backend } from 'react-dnd-html5-backend-latest';

import Indicator from 'components/atoms/Indicator';
import CreatePageHeader from 'components/shared/CreatePageHeader';

import * as S from './CreateAdvertisement.css';
import DictionaryList from './DictionaryList';

import Stepper from 'components/shared/Stepper';
import { stepperData } from './stepperData';
import { api } from 'API';
import useDndAdvCreating from './useDnDAdvCreating';
import { getAttachmentsFromForm } from 'helpers/getAttachmentsFromForm';
import { toast } from 'react-toastify';
import { basicDefaultValues, basicFields } from './basicFields';
import { TOAST_POSITION } from 'constans/constantsToast';

const CreateAdvertisement = () => {
  const mainRef = useRef();
  const history = useHistory();
  const { id } = useParams();

  const [advId, setAdvId] = useState(id);
  const [defaultValues, setDeafultValues] = useState(
    id ? null : basicDefaultValues
  );
  const [currentStep, setCurrentStep] = useState(0);

  const [sections, setSections] = useState([]);
  const [sectionsPreview, setSectionsPreview] = useState([]);
  const [sectionsForm, setSectionsForm] = useState(basicFields);

  const [finishBody, setFinishBody] = useState({});

  const { control, handleSubmit, errors, watch, getValues } = useForm({
    defaultValues: defaultValues || {},
  });

  const getSingleAdv = async () => {
    const { data } = await api.getSingleAdvertisementForCreator(advId);

    const sections = data.sections?.map(section => ({
      accepts: ['subsection'],
      elements: section.dictionaries.map(dict => ({
        ...dict,
        ...dict.dictionaryData,
      })),
      title: section.data,
      id: uuidv4(),
    }));

    setDeafultValues(data);
    setSections(sections);
    setFinishBody(data);
  };

  useEffect(() => {
    if (mainRef?.current) mainRef.current.scrollTop = 0;
    if (advId) getSingleAdv();
    else setDeafultValues({});
    return () => setDeafultValues({});
  }, [advId, currentStep]);

  const sectionsHooks = useDndAdvCreating({ sections, setSections });
  const sectionsFormHooks = useDndAdvCreating({
    sections: sectionsForm,
    setSections: setSectionsForm,
  });

  const updateFinishBody = (values, publish) => {
    let newValues = { ...finishBody };
    delete newValues.publish;

    if (currentStep === 0) newValues = values;
    if (currentStep === 1)
      newValues = {
        ...newValues,
        sections: sectionsPreview.map(el => ({
          ...el,
          dictionaries: el.elements,
        })),
      };
    if (currentStep === 2) {
      let body;

      if (values?.form)
        body = sectionsForm.map((el, idx) => ({
          title: values?.form[idx]?.title,
          dictionaries: el.elements,
        }));

      newValues = {
        ...newValues,
        form: body,
        formDetails: values.formDetails,
      };
    }
    if (currentStep === 3)
      newValues = { ...newValues, finishData: values.finishData, publish };
    return newValues;
  };

  const saveForm = (values, draft) => {
    const attachments = getAttachmentsFromForm(draft ? getValues() : values);

    const formData = new FormData();
    formData.append(
      'content',
      JSON.stringify(draft ? updateFinishBody(getValues()) : values)
    );

    if (attachments?.length) {
      attachments?.forEach(file => {
	let name = '';
        if(Array.isArray(file)) {
          name = file[0].name.toLowerCase();
        } else {
          name = file.name.toLowerCase();
        }

        if(name != null && (name.includes('ą') || name.includes('ć') || name.includes('ę') || name.includes('ł') || name.includes('ń') || name.includes('ó') || name.includes('ś') || name.includes('ż') || name.includes('ź'))) {
          toast.error('Nazwa załączonego pliku nie może zawierać polskich znaków', TOAST_POSITION);
          throw false;
        }

        formData.append('file', file);
      });
    }

    const req = advId
      ? api.putSingleAdvertisement(advId, formData)
      : api.postSingleAdvertisement(formData);

    const sendNewMessage = async () => {
      try {
        if (draft === 'prev') setCurrentStep(prev => prev - 1);
        const { data } = await req;
        setAdvId(data.advertisementId);
        toast.success('Ogłoszenie zostało zapisane', TOAST_POSITION);

        if (currentStep >= 3 && !draft)
          history.push(`/admin/advertisement/${data.advertisementId}`);

        if (currentStep < 3 && !draft) setCurrentStep(prev => prev + 1);
      } catch (e) {
        toast.error(
          <div>
            <p>Wystąpił błąd podczas zapisywania ogłoszenia.</p>
            {e.response?.data?.errors?.map(err => (
              <p>{err.defaultMessage}</p>
            ))}
          </div>,
          TOAST_POSITION
        );
      }
    };
    sendNewMessage();
  };

  const onSubmit = async values => {
    const newValues = updateFinishBody(values, true);
    setFinishBody(newValues);
    saveForm(newValues);
  };

  return (
    <>
      {!defaultValues ? (
        <Indicator />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <CreatePageHeader
            title='Stwórz nowe ogłoszenie'
            history={history}
            subTitle={watch('title')}
            middleButon={{ label: 'Zapisz jako robocze', onClick: saveForm }}
            prevButton={{ label: 'Cofnij', onClick: saveForm }}
            submitLabel={currentStep !== 3 ? 'Następny krok' : 'Publikuj'}
          />
          <DndProvider backend={HTML5Backend}>
            <S.Main ref={mainRef}>
              <DictionaryList
                isDropped={sectionsHooks.isDropped}
                currentStep={currentStep}
              />
              <S.Form>
                <Stepper
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  saveForm={saveForm}
                  steps={stepperData(
                    errors,
                    defaultValues,
                    control,
                    advId,

                    // adv
                    sections,
                    sectionsHooks,

                    // preview
                    sectionsPreview,
                    setSectionsPreview,

                    //form
                    sectionsForm,
                    sectionsFormHooks
                  )}
                />
              </S.Form>
            </S.Main>
          </DndProvider>
        </form>
      )}
    </>
  );
};

export default CreateAdvertisement;
