import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { api } from 'API';
import { toast } from 'react-toastify';
import SingleSection from './SingleSection';
import CreatePageHeader from 'components/shared/CreatePageHeader';
import { getAttachmentsFromForm } from 'helpers/getAttachmentsFromForm';
import { TOAST_POSITION } from 'constans/constantsToast';

const EditApplicationForm = () => {
  const { id } = useParams();
  const history = useHistory();

  const [sectionsForm, setSectionsForm] = useState([]);

  const getSingleForm = async () => {
    try {
      const { data } = await api.getSingleApplicationToEdit(id);
      let sections;
      if (data.sections) {
        sections = data.sections.map((section, index) => ({
          ...section,
          dictionaries: section.dictionaries.map((dict, dictIndex) => {
            const canDuplicate = data.form[index].dictionaries[
              dictIndex
            ]?.elements?.filter(el => el.content?.canDuplicate);
            return {
              ...dict,
              canDuplicate: Boolean(canDuplicate?.length),
            };
          }),
        }));
      } else {
        sections = data.form.map(section => ({
          ...section,
          dictionaries: section?.dictionaries?.map(dict => {
            const canDuplicate = dict?.elements?.filter(
              el => el.content?.canDuplicate
            );

            return {
              ...dict,
              canDuplicate: Boolean(canDuplicate?.length),
              elements: dict.dictionaryData.map(el => ({
                content: {},
              })),
            };
          }),
        }));
      }

      setSectionsForm(sections);
    } catch (e) {
      console.log(e);
      toast.error('Nie udało się pobrać danych formularza', TOAST_POSITION);
    }
  };

  useEffect(() => {
    if (id) getSingleForm();
  }, [id]);

  const addDictionary = (sectionIndex, dictIndex, dict) => {
    const newDict = {
      ...dict,
      elements: dict.elements.map(el => ({ content: {} })),
      isNew: true,
    };

    let dictionaries = sectionsForm[sectionIndex].dictionaries;
    let nextId = 1;

    for (let i = dictIndex + 1; i < dictionaries.length; i++) {
      if (dictionaries[i].isNew) nextId++;
      else break;
    }

    dictionaries.splice(dictIndex + nextId, 0, newDict);

    let newSections = sectionsForm;
    newSections[sectionIndex].dictionaries = dictionaries;

    setSectionsForm([...newSections]);
  };

  const deleteDictionary = (sectionIndex, dictIndex) => {
    const sections = sectionsForm;
    sections[sectionIndex].dictionaries.splice(dictIndex, 1);
    setSectionsForm([...sections]);
  };

  const changeValue = (value, sectionIndex, dictIndex, index, isAtt) => {
    let dictionaries = sectionsForm[sectionIndex].dictionaries[dictIndex];

    // check is content exist
    if (!dictionaries.elements?.[index]) {
      dictionaries.elements[index] = { content: {} };
    }

    if (isAtt === 'att') {
      dictionaries.elements[index].content = value;
    } else {
      dictionaries.elements[index].content = {
        ...value,
      };
    }

    let newSections = sectionsForm;
    newSections[sectionIndex].dictionaries[dictIndex] = dictionaries;
    setSectionsForm([...newSections]);
  };

  const onSubmit = async e => {
    e.preventDefault();

    const attachments = getAttachmentsFromForm({
      sections: sectionsForm,
    });

    const formData = new FormData();
    formData.append('content', JSON.stringify([...sectionsForm]));

    if (attachments?.length) {
      attachments?.forEach(file => {
	let name = '';
        if(Array.isArray(file)) {
          name = file[0].name.toLowerCase();
        } else {
          name = file.name.toLowerCase();
        }

        if(name != null && (name.includes('ą') || name.includes('ć') || name.includes('ę') || name.includes('ł') || name.includes('ń') || name.includes('ó') || name.includes('ś') || name.includes('ż') || name.includes('ź'))) {
          toast.error('Nazwa załączonego pliku nie może zawierać polskich znaków', TOAST_POSITION);
          throw false;
        }

        formData.append('file', file);
      });
    }

    try {
      await api.putSingleApplicationToEdit(id, formData);
      toast.success('Aplikacja została zapisana', TOAST_POSITION);
      history.push('/user/my-applications');
    } catch (e) {
      console.log(e);
      if (e?.response?.data?.errors) {
        e?.response?.data?.errors?.forEach(el => {
          toast.error(el.defaultMessage, TOAST_POSITION);
        });
      } else {
        toast.error('Coś poszło nie tak', TOAST_POSITION);
      }
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <CreatePageHeader title='Wypełnij aplikację' history={history} />
      {sectionsForm.map(({ dictionaries, title, id }, index) => (
        <SingleSection
          key={id}
          dictionaries={dictionaries}
          index={index}
          title={title}
          advId={id}
          addDictionary={addDictionary}
          changeValue={changeValue}
          deleteDictionary={deleteDictionary}
        />
      ))}
    </form>
  );
};

export default EditApplicationForm;
