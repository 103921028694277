import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Checkbox } from '@material-ui/core';
import styled from 'styled-components';

import { api } from 'API';
import { toast } from 'react-toastify';
import SingleSection from './SingleSection';
import CreatePageHeader from 'components/shared/CreatePageHeader';
import { getAttachmentsFromForm } from 'helpers/getAttachmentsFromForm';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { TOAST_POSITION } from 'constans/constantsToast';

export const RegulationsButton = styled.button`
  border: none;
  background: none;
  color: ${({ theme }) => theme.primaryButtonBg};
  text-decoration: underline;
  font-size: 15px;
  cursor: pointer;
`;

const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 200px;
  border: 2px solid ${({ theme }) => theme.green};
  color: ${({ theme }) => theme.green};
  font-weight: 600;
  margin: 30px;
  margin-top: 50px;
  padding: 20px;
  a {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.secondary};
  }
`;

const RequiredStar = styled.span`
  color: #ca001b;
`;

const CheckboxWrap = styled.div`
  padding-left: 30px;
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: ${({ theme }) => theme.secondary};
  }
`;

const ApplicationForm = () => {
  const { id } = useParams();
  const history = useHistory();
  const [regulationChecked, setRegulationChecked] = useState(false);

  const [sectionsForm, setSectionsForm] = useState([]);
  const [errorMessage, setErrorMessages] = useState();

  const getSingleForm = async () => {
    try {
      const { data } = await api.getSingleApplicationForm(id);

      const sections = data.sections.map(section => ({
        ...section,
        dictionaries: section?.dictionaries?.map(dict => {
          const canDuplicate = dict?.elements?.filter(
            el => el.content?.canDuplicate
          );

          return {
            ...dict,
            canDuplicate: Boolean(canDuplicate?.length),
            elements: dict.dictionaryData.map(el => ({
              content: {},
            })),
          };
        }),
      }));

      setSectionsForm(sections);
    } catch (e) {
      setErrorMessages(e.response?.data?.errors);
    }
  };

  useEffect(() => {
    if (id) getSingleForm();
  }, [id]);

  const addDictionary = (sectionIndex, dictIndex, dict) => {
    const newDict = {
      ...dict,
      elements: dict.elements.map(el => ({ content: {} })),
      isNew: true,
    };

    let dictionaries = sectionsForm[sectionIndex].dictionaries;
    let nextId = 1;

    for (let i = dictIndex + 1; i < dictionaries.length; i++) {
      if (dictionaries[i].isNew) nextId++;
      else break;
    }

    dictionaries.splice(dictIndex + nextId, 0, newDict);

    let newSections = sectionsForm;
    newSections[sectionIndex].dictionaries = dictionaries;

    setSectionsForm([...newSections]);
  };

  const deleteDictionary = (sectionIndex, dictIndex) => {
    const sections = sectionsForm;
    sections[sectionIndex].dictionaries.splice(dictIndex, 1);
    setSectionsForm([...sections]);
  };

  const changeValue = (value, sectionIndex, dictIndex, index, isAtt) => {
    let dictionaries = sectionsForm[sectionIndex].dictionaries[dictIndex];

    // check is content exist
    if (!dictionaries.elements?.[index]) {
      dictionaries.elements[index] = { content: {} };
    }

    if (isAtt === 'att') {
      dictionaries.elements[index].content = value;
    } else {
      dictionaries.elements[index].content = {
        ...value,
      };
    }

    let newSections = sectionsForm;
    newSections[sectionIndex].dictionaries[dictIndex] = dictionaries;
    setSectionsForm([...newSections]);
  };

  const onSubmit = async e => {
    e.preventDefault();

    const attachments = getAttachmentsFromForm({
      sections: sectionsForm,
    });

    const formData = new FormData();
    formData.append('content', JSON.stringify([...sectionsForm]));

    if (attachments?.length) {
      attachments?.forEach(file => {
	let name = '';
        if(Array.isArray(file)) {
          name = file[0].name.toLowerCase();
        } else {
          name = file.name.toLowerCase();
        }

        if(name != null && (name.includes('ą') || name.includes('ć') || name.includes('ę') || name.includes('ł') || name.includes('ń') || name.includes('ó') || name.includes('ś') || name.includes('ż') || name.includes('ź'))) {
          toast.error('Nazwa załączonego pliku nie może zawierać polskich znaków', TOAST_POSITION);
          throw false;
        }

        formData.append('file', file);
      });
    }

    try {
      await api.saveSingleApplicationForm(id, formData);
      toast.success('Aplikacja została zapisana', TOAST_POSITION);
      history.push('/user/my-applications');
    } catch (e) {
      console.log(e);
      if (e?.response?.data?.errors) {
        e?.response?.data?.errors?.forEach(el => {
          toast.error(el.defaultMessage, TOAST_POSITION);
        });
      } else {
        toast.error('Coś poszło nie tak', TOAST_POSITION);
      }
    }
  };

  const showRegulation = async type => {
    try {
      const { data } = await api.getPublicFile(type);

      const blob = new Blob([data], {
        type: 'application/pdf',
      });
      const url = URL.createObjectURL(blob);
      window.open(url);
      URL.revokeObjectURL(url);
    } catch (e) {
      toast.error('Wystąpił błąd podczas pobierania pliku', TOAST_POSITION);
    }
  };

  if (errorMessage) {
    return (
      <ErrorMessage>
        {errorMessage?.map(err => (
          <p>{err.defaultMessage}</p>
        ))}
        <Link to='/'>
          <ChevronLeftIcon aria-hidden='false'>
            <title>Powróć do strony głównej</title>
          </ChevronLeftIcon>
          Powróć do strony głównej
        </Link>
      </ErrorMessage>
    );
  } else {
    return (
      <form onSubmit={onSubmit}>
        <CreatePageHeader
          title='Wypełnij aplikację'
          history={history}
          submitLabel='Aplikuj'
        />
        {sectionsForm.map(({ dictionaries, title, id }, index) => (
          <SingleSection
            key={id}
            dictionaries={dictionaries}
            index={index}
            title={title}
            advId={id}
            addDictionary={addDictionary}
            changeValue={changeValue}
            deleteDictionary={deleteDictionary}
          />
        ))}

        <CheckboxWrap>
          <Checkbox
            color='primary'
            value={regulationChecked}
            onChange={e => {
              e.target.setCustomValidity(
                !e.target.checked ? 'To pole jest wymagane.' : ''
              );

              setRegulationChecked(prev => !prev);
            }}
            required={true}
          />
          Oświadczam, iż zapoznałem/am się z
          <RegulationsButton
            type='button'
            onClick={() => showRegulation('RECRUITMENT_TERMS')}
          >
            regulaminem
          </RegulationsButton>
          naborów.
          <RequiredStar>*</RequiredStar>
        </CheckboxWrap>
        <CheckboxWrap>
          <Checkbox
            color='primary'
            value={regulationChecked}
            onChange={e => {
              e.target.setCustomValidity(
                !e.target.checked ? 'To pole jest wymagane.' : ''
              );

              setRegulationChecked(prev => !prev);
            }}
            required={true}
          />
          Oświadczam, iż zapoznałem/am się z
          <RegulationsButton
            type='button'
            onClick={() =>
              showRegulation('INFORMATION_ON_THE_PROCESSING_OF_PERSONAL_DATA')
            }
          >
            Informacja o przetwarzaniu danych osobowych
          </RegulationsButton>
          <RequiredStar>*</RequiredStar>
        </CheckboxWrap>
      </form>
    );
  }
};

export default ApplicationForm;
